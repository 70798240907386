import React from 'react';
import PropTypes from 'prop-types';
import { PluginStore } from 'graylog-web-plugin/plugin';
import moment from 'moment';

import Timestamp from 'components/common/Timestamp';
import GraphVisualization from 'components/visualizations/GraphVisualization';
import DateTime from 'logic/datetimes/DateTime';
import StoreProvider from 'injection/StoreProvider';

const StreamsStore = StoreProvider.getStore('Streams');
const FieldStatisticsStore = StoreProvider.getStore('FieldStatistics');

class WidgetDescription extends React.Component {
  static propTypes = {
    widget: PropTypes.object.isRequired,
    calculatedAt: PropTypes.string,
  };

  static defaultProps = {
    calculatedAt: undefined,
  };

  constructor(props) {
    super(props);
    this.state = this._cleanState();
  }

  componentDidMount() {
    this._loadStream();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.widget.dashboard_widget_id !== this.props.widget.dashboard_widget_id) {
      this.setState(this._cleanState());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.widget.dashboard_widget_id !== this.props.widget.dashboard_widget_id) {
      this._loadStream();
    }
  }

  _cleanState = () => {
    return {
      stream: undefined,
    };
  };

  _loadStream = () => {
    const streamId = this.props.widget.config.stream_id;
    if (streamId) {
      StreamsStore.get(streamId, stream => this.setState({ stream: stream }));
    }
  };

  _timeRangeDescription = (timeRange) => {
    let description = '';
    switch (timeRange.type) {
      case 'relative':
        description = <span>in the last {moment.duration(timeRange.range, 'seconds').humanize()}</span>;
        break;
      case 'absolute':
        description = <span>from <Timestamp dateTime={timeRange.from} /> to <Timestamp dateTime={timeRange.to} /></span>;
        break;
      case 'keyword':
        description = <span>in the {timeRange.keyword}</span>;
        break;
      default:
        // Do nothing
    }

    return description;
  };

  _calculatedAtDescription = (calculatedAt) => {
    return <span>was calculated at <Timestamp dateTime={calculatedAt} format={DateTime.Formats.COMPLETE} /></span>;
  };

  _singleSeriesDescription = (config, calculatedAt) => {
    const statisticalFunction = config.statistical_function ||
      config.stats_function ||
      GraphVisualization.getReadableFieldChartStatisticalFunction(config.valuetype); // oh, the inconsistency...
    let formattedStatisticalFunction;
    if (statisticalFunction) {
      formattedStatisticalFunction = FieldStatisticsStore.FUNCTIONS.get(statisticalFunction);
    }

    return (
      <span>
        The visualization represents data for{' '}
        query <em>{config.query || '*'}</em>{' '}
        in {config.field ? <span>field <em>{config.field}</em></span> : 'all fields'}{' '}
        {formattedStatisticalFunction &&
        ` using a ${formattedStatisticalFunction.toLowerCase()} statistical function`}{' '}
        and {this._calculatedAtDescription(calculatedAt)}.
      </span>
    );
  };

  _multipleSeriesDescription = (config, calculatedAt) => {
    const seriesDescriptions = config.series.map((series, idx, array) => {
      const statisticalFunction = FieldStatisticsStore.FUNCTIONS.get(series.statistical_function).toLowerCase();
      const seriesNo = idx + 1;
      return (
        <span key={seriesNo}>
          series {seriesNo}:{' '}
          {statisticalFunction} of field <em>{series.field}</em> for query <em>{series.query || '*'}</em>
          {seriesNo !== array.length && '; '}
        </span>
      );
    });

    return (
      <span>
        The visualization contains {config.series.length} time series ({seriesDescriptions}){' '}
        and {this._calculatedAtDescription(calculatedAt)}.
      </span>
    );
  };

  render() {
    const { widget, calculatedAt } = this.props;
    const stream = this.state.stream || {};
    const config = widget.config;
    const widgetPlugin = PluginStore.exports('widgets').find(w => w.type.toUpperCase() === widget.type.toUpperCase());

    if (!widgetPlugin) {
      return <span>No description available.</span>;
    }

    let description;

    if (config.series) {
      description = this._multipleSeriesDescription(config, stream, calculatedAt);
    } else {
      description = this._singleSeriesDescription(config, stream, calculatedAt);
    }

    return (
      <span>
        {widgetPlugin.displayName} for{' '}
        {config.stream_id ? <span>messages in stream <em>{stream.title || config.stream_id}</em></span> : 'all messages'}{' '}
        {this._timeRangeDescription(config.timerange)}.{' '}
        {description}
      </span>
    );
  }
}

export default WidgetDescription;
