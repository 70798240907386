import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import NumberUtils from 'util/NumberUtils';
import moment from 'moment';

import LicenseTerm from 'license/LicenseTerm';

const LicenseDetails = createReactClass({
  propTypes: {
    trial: PropTypes.bool.isRequired,
    version: PropTypes.number.isRequired,
    enterprise: PropTypes.oneOfType([
      PropTypes.shape({
        number_of_nodes: PropTypes.number,
      }),
      PropTypes.shape({
        traffic_limit: PropTypes.number,
        require_remote_check: PropTypes.bool,
        allowed_remote_check_failures: PropTypes.number,
        allowed_traffic_violations: PropTypes.number,
        traffic_check_range: PropTypes.number,
        expiration_warning_range: PropTypes.number,
      }),
    ]).isRequired,
    violations: PropTypes.shape({
      cluster_not_covered: PropTypes.bool,
      nodes_exceeded: PropTypes.bool,
      traffic_exceeded: PropTypes.bool,
      remote_checks_failed: PropTypes.bool,
      expired: PropTypes.bool,
      violated: PropTypes.bool,
    }).isRequired,
  },

  render() {
    const details = [];

    const violations = this.props.violations;
    // first list the possible generic violations that can happen
    details.push(<LicenseTerm key="violation" violated={violations.violated} warning="License is violated!" />);
    details.push(<LicenseTerm key="expired" violated={violations.expired} warning="License has expired!" />);
    details.push(<LicenseTerm key="not-covered"
                              violated={violations.cluster_not_covered}
                              warning="This cluster is not covered by the license." />);

    // mark trial licenses so they can be spotted more easily when replacing them with a real one
    if (this.props.trial) {
      details.push(<span key="trial"><b>Trial License</b><br /></span>);
    }

    const enterprise = this.props.enterprise;
    if (this.props.version === 1) {
      details.push(<LicenseTerm key="node-count"
                                violated={violations.nodes_exceeded}
                                warning="Too many nodes in cluster!">
        <span>{`Maximum nodes in cluster: ${enterprise.number_of_nodes}`}</span>
      </LicenseTerm>);
    } else if (this.props.version === 2) {
      const limit = NumberUtils.formatBytes(enterprise.traffic_limit);
      const allowedViolations = enterprise.allowed_traffic_violations;
      const trafficCheckRange = moment.duration(enterprise.traffic_check_range);

      details.push(<LicenseTerm key="ingest"
                                violated={violations.traffic_exceeded}
                                warning="Daily traffic limit has been exceeded too many times.">
        <span>{`Daily traffic limit: ${limit} (allowed violations per ${trafficCheckRange.as('days')} days: ${allowedViolations})`}</span>
      </LicenseTerm>);

      const remoteCheck = enterprise.require_remote_check;
      details.push(<LicenseTerm key="remote-check"
                                violated={violations.remote_checks_failed}
                                warning="Remote checks have failed too many times.">
        {`Requires remote checks: ${remoteCheck ? 'Yes' : 'No'}`}
        {remoteCheck ? ` (allowed consecutive check failures: ${enterprise.allowed_remote_check_failures})` : null}
      </LicenseTerm>);
      const expirationWarning = moment.duration(enterprise.expiration_warning_range);
      details.push(<span key="expiration-warn">
        {`License expiration warning: ${expirationWarning.as('days')} days before`}
        <br />
      </span>);
    } else {
      details.push(<span key="unknown-version">Unknown license version, cannot display details.<br /></span>);
    }

    return <div>{details}</div>;
  },
});

export default LicenseDetails;
