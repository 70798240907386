import PropTypes from 'prop-types';
import React from 'react';
import lodash from 'lodash';
import { Col, Row } from 'react-bootstrap';

import { Spinner } from 'components/common';
import history from 'util/History';
import { naturalSortIgnoreCase } from 'util/SortUtils';
import CombinedProvider from 'injection/CombinedProvider';
import Routes from 'routing/Routes';

import ReportContentsSelection from './ReportContentsSelection';
import ReportSummary from './ReportSummary';
import ReportContentsToolbar from './ReportContentsToolbar';
import ReportsActions from '../ReportsActions';

const { DashboardsActions } = CombinedProvider.get('Dashboards');

class ReportContents extends React.Component {
  static propTypes = {
    report: PropTypes.object,
    reportLogo: PropTypes.string,
    action: PropTypes.oneOf(['create', 'edit']),
  };

  static defaultProps = {
    report: {
      title: '',
      subtitle: '',
      description: '',
      widgets: [],
      positions: [],
    },
    reportLogo: null,
    action: 'create',
  };

  constructor(props) {
    super(props);
    this.state = this._initState(props);
  }

  componentDidMount() {
    DashboardsActions.list().then((state) => {
      const sortedDashboards = state.dashboards.toJS()
        .sort((d1, d2) => naturalSortIgnoreCase(d1.title, d2.title));
      this.setState({ dashboards: sortedDashboards });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.report.id !== nextProps.report.id) {
      this.setState(this._initState(nextProps));
    }
    if (this.props.reportLogo !== nextProps.reportLogo) {
      this.setState({ reportLogo: nextProps.reportLogo });
    }
  }

  _initState = (props) => {
    return {
      report: lodash.cloneDeep(props.report),
      reportLogo: props.reportLogo,
      dashboards: undefined,
      dataTouched: false,
      logoChanged: false,
      isSubmitting: false,
    };
  };

  _updateReport = (updatedReport) => {
    this.setState({ report: updatedReport, dataTouched: true });
  };

  _updateReportLogo = (nextReportLogo) => {
    this.setState({ reportLogo: nextReportLogo, dataTouched: true, logoChanged: true });
  };

  _confirmNavigation = (event) => {
    if (!this.state.dataTouched) {
      return;
    }

    if (!window.confirm('Do you really want to abandon this page and lose your changes? This action cannot be undone.')) {
      event.preventDefault();
    }
  };

  _saveReport = () => {
    this.setState({ isSubmitting: true });
    const callback = () => history.push(Routes.pluginRoute('REPORTS'));
    const resetState = () => this.setState({ isSubmitting: false });

    if (this.props.action === 'create') {
      ReportsActions.create(this.state.report, this.state.reportLogo).then(callback, resetState);
    } else {
      ReportsActions.update(this.state.report, this.state.reportLogo).then(callback, resetState);
    }
  };

  render() {
    const { dashboards, report, reportLogo, isSubmitting } = this.state;
    const { action } = this.props;
    const formId = 'report-contents-selection-form';
    const isLoading = !dashboards;

    if (isLoading) {
      return <div><Spinner text="Loading report data, please wait..." /></div>;
    }

    return (
      <Row>
        <Col md={6}>
          <ReportContentsSelection formElementId={formId}
                                   report={report}
                                   reportLogo={reportLogo}
                                   dashboards={dashboards}
                                   onReportChange={this._updateReport}
                                   onReportLogoChange={this._updateReportLogo}
                                   onSaveReport={this._saveReport}
                                   onCancel={this._confirmNavigation}
                                   action={action}
                                   isLoading={isSubmitting} />
        </Col>
        <Col md={5} mdOffset={1}>
          <ReportSummary report={report} reportLogo={reportLogo}>
            <ReportContentsToolbar action={action}
                                   onCancel={this._confirmNavigation}
                                   formElementId={formId}
                                   isLoading={isSubmitting} />
          </ReportSummary>
        </Col>
      </Row>
    );
  }
}

export default ReportContents;
