exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._345dcY1IaqNI2CcWZdsmJa{margin:-10px -10px 0}._345dcY1IaqNI2CcWZdsmJa.unlocked .react-draggable{cursor:move}._345dcY1IaqNI2CcWZdsmJa.locked .react-resizable-handle{display:none}._345dcY1IaqNI2CcWZdsmJa .react-grid-placeholder{background:#16ace3}._345dcY1IaqNI2CcWZdsmJa .actions{cursor:default}", ""]);

// exports
exports.locals = {
	"reactGridLayout": "_345dcY1IaqNI2CcWZdsmJa"
};