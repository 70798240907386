import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router';
import Routes from 'routing/Routes';

import styles from './LicenseNotification.css';

/**
 * Renders a alert for expired licenses.
 */
class LicenseExpirationNotification extends React.Component {
  static propTypes = {
    /** Duration string since a license expired. (e.g. "3 months") */
    expiredSince: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className={`container-fluid ${styles.licenseNotification}`}>
        <Alert bsStyle="danger">
          <h4 style={{ marginBottom: 5 }}><strong>Graylog Enterprise License Expired</strong></h4>
          <p>
            Your license <strong>expired {this.props.expiredSince} ago</strong> and Graylog Enterprise features have been disabled.
            Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>License page</Link> for
            more information or contact your Graylog account manager.
          </p>
        </Alert>
      </div>
    );
  }
}

export default LicenseExpirationNotification;
