import Reflux from 'reflux';
import UserNotification from 'util/UserNotification';
import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import ReportLicenseActions from './ReportLicenseActions';

const licenseStatusUrl = '/plugins/org.graylog.plugins.license/licenses/status/for-subject?subject=/license/enterprise/report';

const ReportLicenseStore = Reflux.createStore({
  listenables: [ReportLicenseActions],

  getInitialState() {
    return {
      status: undefined,
      missing: true,
      loading: true,
    };
  },

  getLicenseStatus() {
    const promise = fetch('GET', URLUtils.qualifyUrl(licenseStatusUrl));
    const errorHandler = this._errorHandler('Fetching report license failed', 'Could not retrieve report license');

    promise.then((response) => {
      this.trigger({ status: response.status, missing: false, loading: false });
    }, (error) => {
      try {
        // We don't want to create a user notification for missing licenses
        if (error.additional.status !== 404) {
          errorHandler(error);
        }
      } catch (e) {
        errorHandler(error);
      }
      this.trigger({ status: null, missing: true, loading: false });
    });

    ReportLicenseActions.getLicenseStatus.promise(promise);
  },

  _errorHandler(message, title) {
    return (error) => {
      let errorMessage;
      try {
        errorMessage = error.additional.body.message;
      } catch (e) {
        errorMessage = error.message;
      }
      UserNotification.error(`${message}: ${errorMessage}`, title);
    };
  },
});

export default ReportLicenseStore;
