exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2l784rTY9IYWVMfHLrjuYP.list-group-item{background-color:#f1f2f2;font-size:14px;padding:0 15px}._2l784rTY9IYWVMfHLrjuYP .form-group{margin:0}._1nFhSrvWFUj3qGJMgAbtX-{margin:10px 0;min-height:20px}", ""]);

// exports
exports.locals = {
	"listGroupHeader": "_2l784rTY9IYWVMfHLrjuYP",
	"headerWrapper": "_1nFhSrvWFUj3qGJMgAbtX-"
};