import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Timestamp } from 'components/common';

class AuditLogTableEntry extends React.Component {
  static propTypes = {
    entry: PropTypes.object.isRequired,
  };

  state = {
    showDetails: false,
  };

  componentWillReceiveProps() {
    // Make sure to close details when re-render
    this.setState({ showDetails: false });
  }

  toggleDetails = (e) => {
    e.preventDefault();
    this.setState({ showDetails: !this.state.showDetails });
  };

  bodyStyle = () => {
    return this.state.showDetails ? 'auditlog-entry details-visible' : 'auditlog-entry';
  };

  attributes = () => {
    const attributeList = [];

    Object.keys(this.props.entry.attributes).forEach(key => {
      attributeList.push(<dt key={`${key}-title`}>{key}</dt>);
      attributeList.push(<dd key={`${key}-description`}>{this.props.entry.attributes[key]}</dd>);
    });

    return attributeList;
  };

  details = () => {
    return (
      <Row>
        <Col md={6}>
          <dl>
            <dt>Actor</dt>
            <dd>{this.props.entry.actor}</dd>
            <dt>Namespace</dt>
            <dd>{this.props.entry.namespace}</dd>
            <dt>Object</dt>
            <dd>{this.props.entry.object}</dd>
            <dt>Action</dt>
            <dd>{this.props.entry.action}</dd>
            <dt>Success status</dt>
            <dd>{this.props.entry.success_status}</dd>
            <dt>Node ID</dt>
            <dd>{this.props.entry.node_id}</dd>
          </dl>
        </Col>
        <Col md={6}>
          <dl>
            {this.attributes()}
          </dl>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <tbody className={this.bodyStyle()}>
        <tr onClick={this.toggleDetails} className="toggle-details">
          <td><Timestamp tz="browser" dateTime={this.props.entry.timestamp}/></td>
          <td>{this.props.entry.actor_formatted}</td>
          <td>{this.props.entry.action}</td>
          <td className="auditlog-message">{this.props.entry.message}</td>
        </tr>
        {this.state.showDetails &&
          <tr className="auditlog-entries-details">
            <td colSpan="4">
              {this.details()}
            </td>
          </tr>
        }
      </tbody>
    );
  }
}

export default AuditLogTableEntry;
