exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1BWvQorww983fIs9ZyG2-Z{margin-top:10px}._3jeuGPHBKSiQlm1dDsmZRu,.yqY8uPLK_UE357mBjbTbm{max-height:400px;margin-top:10px}.yqY8uPLK_UE357mBjbTbm{overflow:auto}", ""]);

// exports
exports.locals = {
	"optionsFormWrapper": "_1BWvQorww983fIs9ZyG2-Z",
	"spinnerWrapper": "_3jeuGPHBKSiQlm1dDsmZRu",
	"visualizationWrapper": "yqY8uPLK_UE357mBjbTbm"
};