exports = module.exports = require("../../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._133083MchXDJhLVY39G8af table.messages thead,._133083MchXDJhLVY39G8af table.messages thead th{background-color:#eee;color:#333}._3ndvKyvU9gGcpOB8BNy8Zo #message-table-paginator-top{margin-bottom:12px}", ""]);

// exports
exports.locals = {
	"messageListTableHeader": "_133083MchXDJhLVY39G8af",
	"messageListPaginator": "_3ndvKyvU9gGcpOB8BNy8Zo"
};