exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._39PhDND03Lsj0iCL7CoJE8{border:1px outset #aaa;font-size:14px;margin-bottom:5px;padding:3em;width:95%}", ""]);

// exports
exports.locals = {
	"paperSheet": "_39PhDND03Lsj0iCL7CoJE8"
};