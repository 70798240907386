// @flow strict
import * as React from 'react';
import PropTypes from 'prop-types';
import * as Immutable from 'immutable';

// $FlowFixMe: imports from core need to be fixed in flow
import connect from 'stores/connect';
import ParameterDeclarationForm from 'enterprise/components/parameters/ParameterDeclarationForm';
import {
  newParameterState,
  SearchParameterActions,
  SearchParameterStore,
} from 'enterprise/stores/SearchParameterStore';
import type { ParameterMap } from 'enterprise/logic/parameters/Parameter';

type Props = {
  onClose: () => void,
  searchParameters: ParameterMap
};

class CreateParameterDialog extends React.Component<Props> {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };
  onSave = (newParameters: ParameterMap) => {
    SearchParameterActions.declare(newParameters).then(this.props.onClose);
  };
  render() {
    const newParameters = Immutable.fromJS({
      newParameter: newParameterState('newParameter'),
    });
    return <ParameterDeclarationForm allowEditingName show parameters={newParameters} onSave={this.onSave} onClose={this.props.onClose} />;
  }
}

export default connect(CreateParameterDialog, { searchParameters: SearchParameterStore });