exports = module.exports = require("../../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1lUb3ayc3yHt-7OKbhdv4x{font-size:18px;height:25px;text-overflow:ellipsis;margin-bottom:5px}._2Tz354wf5Tm40yQKunNsp_{position:relative;z-index:1}.Y9Do0KyF9B-tKxYf8hSZU{cursor:move;opacity:.3}", ""]);

// exports
exports.locals = {
	"widgetHeader": "_1lUb3ayc3yHt-7OKbhdv4x",
	"widgetActionDropdown": "_2Tz354wf5Tm40yQKunNsp_",
	"widgetDragHandle": "Y9Do0KyF9B-tKxYf8hSZU"
};