exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3-rOzZ7mANTzg8V2bJ65Tp .popover-content{padding:0}", ""]);

// exports
exports.locals = {
	"customPopover": "_3-rOzZ7mANTzg8V2bJ65Tp"
};