import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row } from 'react-bootstrap';
import lodash from 'lodash';

import PaperSheet from '../common/PaperSheet';
import ReportPreview from '../common/ReportPreview';

import ReportsActions from '../ReportsActions';

import style from './ReportLayout.css';
import commonStyles from '../commonStyles.css';

class ReportLayout extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    reportLogo: PropTypes.string,
  };

  static defaultProps = {
    reportLogo: undefined,
  };

  _onPositionsChange = (positions) => {
    const updatedPositions = positions.map((position) => {
      return {
        dashboard_widget_id: position.id,
        row: position.row,
        col: position.col,
      };
    });

    if (!lodash.isEqual(updatedPositions, this.props.report.positions)) {
      ReportsActions.updatePositions(this.props.report.id, updatedPositions);
    }
  };

  _renderReportContents = (report) => {
    if (report.widgets.length === 0) {
      return (
        <Alert bsStyle="info" className={style.alert}>
          <i className="fa fa-info-circle" /> <strong>No widgets have been added to this report.</strong>{' '}
          Modify the report&#039;s content and select which widgets you want to include in the report.
        </Alert>
      );
    }

    return <ReportPreview report={report} onPositionsChange={this._onPositionsChange} />;
  };

  render() {
    const { report, reportLogo } = this.props;

    return (
      <div>
        <h3>Layout</h3>
        <p>
          This is a preview of the report. Drag and drop widgets to sort the report contents, your changes will be
          updated automatically.
        </p>
        <PaperSheet>
          <Row className={style.coverPage}>
            <Col md={12}>
              <h2 className="text-center">{report.title}</h2>
              <h4 className="text-center">{report.subtitle}</h4>
              {reportLogo && <div className={`${style.logo} ${commonStyles.logo}`}><img src={reportLogo} alt="report-logo" /></div>}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={12}>
              <p className={style.description}>{report.description}</p>
              {this._renderReportContents(report)}
            </Col>
          </Row>
        </PaperSheet>
      </div>
    );
  }
}

export default ReportLayout;
