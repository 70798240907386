// @flow strict
import * as React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';

// $FlowFixMe: imports from core need to be fixed in flow
import Select from 'components/common/Select';
// $FlowFixMe: imports from core need to be fixed in flow
import Spinner from 'components/common/Spinner';

// $FlowFixMe: imports from core need to be fixed in flow
import { BootstrapModalForm, Input } from 'components/bootstrap';
import { ViewManagementActions } from 'enterprise/stores/ViewManagementStore';
import Parameter from 'enterprise/logic/parameters/Parameter';
import type { ViewSummaries, ViewSummary } from 'enterprise/stores/ViewManagementStore';

import styles from './SelectViewWithParameter.css';

type State = {
  viewSummaries: ?ViewSummaries,
  selectedView: ?ViewSummary,
  selectedParameter: ?string,
};

type Props = {
  onSubmit: (?string, ?string) => void,
};

class SelectViewWithParameter extends React.Component<Props, State> {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    viewSummaries: undefined,
    selectedParameter: undefined,
    selectedView: undefined,
  };

  componentDidMount() {
    ViewManagementActions.forValue().then((viewSummaries: ViewSummaries) => this.setState({ viewSummaries }));
  }

  _onSubmit = () => this.props.onSubmit(this.state.selectedView && this.state.selectedView.id, this.state.selectedParameter);

  _onChange = (selectedView: string) => {
    const { viewSummaries } = this.state;
    if (selectedView && viewSummaries) {
      const view: ?ViewSummary = viewSummaries.find(v => v.id === selectedView);
      if (view) {
        const parameter = view.parameters[0];
        this.setState({
          selectedView: view,
          selectedParameter: parameter.name,
        });
        return;
      }
    }
    this.setState({
      selectedParameter: undefined,
      selectedView: undefined,
    });
  };

  // eslint-disable-next-line no-undef
  _onParameterChange = (event: SyntheticInputEvent<HTMLInputElement>) => this.setState({ selectedParameter: event.target.value });

  // eslint-disable-next-line react/no-unused-prop-types
  _renderOption = ({ label, help }: { label: string, help: string }) => <React.Fragment><strong>{label}</strong>{help && ` - ${help}`}</React.Fragment>;

  render() {
    const { viewSummaries, selectedView } = this.state;

    let content = <Spinner />;
    if (viewSummaries) {
      const viewOptions = viewSummaries
        .sort((v1, v2) => v1.title.localeCompare(v2.title))
        .map((viewSummary: ViewSummary) => ({ label: viewSummary.title, value: viewSummary.id, help: viewSummary.summary }));

      let parameterSelection = null;

      if (selectedView) {
        const { parameters } = selectedView;
        parameterSelection = (
          <React.Fragment>
            <FormGroup className={styles.indentingParameters}>
              <ControlLabel>Select Parameter:</ControlLabel>
              {parameters.map((parameter: Parameter) => (<Input type="radio"
                                                                key={`radio-parameter-${parameter.name}`}
                                                                id={parameter.name}
                                                                name="parameter"
                                                                value={parameter.name}
                                                                label={parameter.title}
                                                                onChange={this._onParameterChange}
                                                                help={parameter.description}
                                                                checked={this.state.selectedParameter === parameter.name} />))}
            </FormGroup>
          </React.Fragment>
        );
      }

      content = (
        <React.Fragment>

          <FormGroup>
            <Select placeholder="Select view"
                    options={viewOptions}
                    optionRenderer={this._renderOption}
                    onChange={this._onChange}
                    clearable
                    value={selectedView && selectedView.id} />
          </FormGroup>

          {parameterSelection}

        </React.Fragment>
      );
    }

    return (
      <BootstrapModalForm title="Select view"
                          submitButtonDisabled={!this.state.selectedParameter}
                          show
                          onSubmitForm={this._onSubmit}>
        {content}
      </BootstrapModalForm>
    );
  }
}

export default SelectViewWithParameter;
