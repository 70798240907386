import React from 'react';
import PropTypes from 'prop-types';

import connect from 'stores/connect';
import { ViewsLicenseStore } from 'enterprise/stores/ViewsLicenseStore';
import Spinner from 'components/common/Spinner';
import CustomPropTypes from '../CustomPropTypes';

const validLicensePresent = license => (license && !license.missing && license.status && license.status.valid);

const LicenseCheck = ({ children, license, errorComponent }) => {
  if (license && license.loading) {
    return <Spinner />;
  }
  const ErrorComponent = errorComponent;
  if (!validLicensePresent(license)) {
    return <ErrorComponent license={license} />;
  }
  return children;
};

LicenseCheck.propTypes = {
  children: CustomPropTypes.OneOrMoreChildren.isRequired,
  errorComponent: PropTypes.func.isRequired,
  license: PropTypes.shape({
    status: PropTypes.object,
    missing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(LicenseCheck, { license: ViewsLicenseStore });
