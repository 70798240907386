exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".jTC2i_wi9gXLGxcnw_Dqf{margin-bottom:10px}.jTC2i_wi9gXLGxcnw_Dqf img{border:1px solid #e3e3e3}._13Ks0MqnwQ96Kojw9cgui_{margin-bottom:10px}", ""]);

// exports
exports.locals = {
	"logo": "jTC2i_wi9gXLGxcnw_Dqf",
	"removeLogo": "_13Ks0MqnwQ96Kojw9cgui_"
};