exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media print{._1D6vf9xdYHG09Xss5Umxt_{page-break-inside:avoid}.datatable-badge{background:inherit!important;-webkit-print-color-adjust:exact}.quickvalues-table .table thead th{font-weight:700;border-bottom:2px solid currentColor;color:#333!important}.quickvalues-table .table .dc-table-group td{border-bottom:1px solid #333}}", ""]);

// exports
exports.locals = {
	"pieChart": "_1D6vf9xdYHG09Xss5Umxt_"
};