import PropTypes from 'prop-types';

import React from 'react';
import { AutoAffix } from 'react-overlays';

import { Pluralize } from 'components/common';

import style from './ReportSummary.css';
import commonStyles from '../commonStyles.css';

class ReportSummary extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    reportLogo: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  };

  static defaultProps = {
    reportLogo: null,
    children: [],
  };

  render() {
    const { report, reportLogo, children } = this.props;
    const numberWidgets = report.widgets.length;
    // report.logo contains the edited logo that will be sent to the server
    // reportLogo contains the existing logo for a report, as we fetch that in a separate API call.

    return (
      <AutoAffix viewportOffsetTop={65}>
        <div>
          <h3>Report summary</h3>
          <p>
            This is a summary of contents included in the report. You may modify them later if necessary.
          </p>
          <div className={style.reportPreview}>
            <dl>
              <dt>Title</dt>
              <dd>{report.title || 'No report title given.'}</dd>
              <dt>Subtitle</dt>
              <dd>{report.subtitle || 'No report subtitle given.'}</dd>
              <dt>Logo</dt>
              <dd>
                {reportLogo ?
                  <div className={`${commonStyles.logo} ${style.logo}`}><img src={reportLogo} alt="report-logo" /></div> :
                  'No report logo given.'}
              </dd>
              <dt>Description</dt>
              <dd className={style.descriptionPreview}>{report.description || 'No report description given.'}</dd>
              <dt>Widgets</dt>
              <dd>
                {numberWidgets ?
                  <span>{numberWidgets} <Pluralize singular="widget" plural="widgets" value={numberWidgets} /> currently
                    included in the report.</span> :
                  <span>No widgets currently included in the report.</span>}
              </dd>
            </dl>
            {children}
          </div>
        </div>
      </AutoAffix>
    );
  }
}

export default ReportSummary;
