exports = module.exports = require("../../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3dsOkQl7xFXpwwwpKi53li dl.message-details-fields{color:#666}._3dsOkQl7xFXpwwwpKi53li dl.message-details-fields dd{font-family:monospace}", ""]);

// exports
exports.locals = {
	"messageFields": "_3dsOkQl7xFXpwwwpKi53li"
};