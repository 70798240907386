import React from 'react';
import createReactClass from 'create-react-class';

import ReportLicenseStatusContainer from './validations/ReportLicenseStatusContainer';
import ReportTokenAuthStatus from './validations/ReportTokenAuthStatus';

const ReportValidations = createReactClass({
  render() {
    const licenseValidation = <ReportLicenseStatusContainer rowClass="content row-sm" />;
    const authValidation = <ReportTokenAuthStatus />;

    return (
      <div>
        {licenseValidation}
        {authValidation}
      </div>
    );
  },
});

export default ReportValidations;
