import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import BootstrapModalForm from 'components/bootstrap/BootstrapModalForm';
import { Spinner } from 'components/common';
import { Input } from 'components/bootstrap';

import ArchiveActions from 'archive/ArchiveActions';

class ArchiveBackendDeletionForm extends React.Component {
  static propTypes = {
    backend: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  state = { config: undefined, deleteArchives: false };

  open = () => {
    this.modal.open();
  };

  close = () => {
    this.modal.close();
  };

  _onModalOpen = () => {
    ArchiveActions.config.triggerPromise().then(config => this.setState({ config: config }));
  };

  _onDelete = (e) => {
    e.preventDefault();

    if (this._isDeletable()) {
      this.props.onDelete(this.props.backend, this.state.deleteArchives);
    }
  };

  _onRemoveClick = (e) => {
    this.setState({ deleteArchives: e.target.checked });
  };

  _isLoading = () => {
    return !this.state.config;
  };

  _isDeletable = () => {
    return !this._isLoading() && this.state.config.backend_id !== this.props.backend.id;
  };

  _modalContent = () => {
    if (this._isLoading()) {
      return <Spinner text="Loading assigned streams..." />;
    }

    if (!this._isDeletable()) {
      return (
        <div>
          <Row>
            <Col md={12}>
              <Alert bsStyle="danger">
                The <em>{this.props.backend.title}</em> backend cannot be deleted because it is in use.
              </Alert>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <Row>
        <Col md={12}>
          <Input type="checkbox"
                 id="remove-data-checkbox"
                 label="Remove all archive data for this backend?"
                 help={<span>All archives related to this backend will be deleted.</span>}
                 checked={this.state.deleteArchives}
                 onChange={this._onRemoveClick} />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <BootstrapModalForm ref={(c) => { this.modal = c; }}
                          title={`Delete backend "${this.props.backend.title}"?`}
                          onModalOpen={this._onModalOpen}
                          onSubmitForm={this._onDelete}
                          submitButtonText="Delete"
                          submitButtonDisabled={!this._isDeletable()}>
        {this._modalContent()}
      </BootstrapModalForm>
    );
  }
}

export default ArchiveBackendDeletionForm;
