import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import { DocumentTitle, PageHeader } from 'components/common';

import DocsHelper from 'util/DocsHelper';
import Routes from 'routing/Routes';

import ArchiveBackends from 'archive/components/ArchiveBackends';
import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';
import ArchiveActions from 'archive/ArchiveActions';
import ArchiveStore from 'archive/ArchiveStore';

const style = require('!style/useable!css!./components/ArchiveBackends.css');

const ArchiveBackendsPage = createReactClass({
  displayName: 'ArchiveBackendsPage',

  mixins: [
    Reflux.connect(ArchiveStore),
  ],

  componentDidMount() {
    style.use();
    ArchiveActions.listBackends(this.state.backends.pagination.page, this.state.backends.pagination.per_page);
  },

  componentWillUnmount() {
    style.unuse();
  },

  _onDelete(backend, deleteArchives) {
    ArchiveActions.deleteBackend(backend, deleteArchives);
  },

  _onPageChange(newPage, pageSize) {
    ArchiveActions.listBackends(newPage, pageSize);
  },

  render() {
    return (
      <DocumentTitle title="Archive backend configuration">
        <div>
          <PageHeader title="Edit archive backend configuration">
            <span>
              The Graylog archive feature allows you to create archives from indices. The generated archives
              are simple flat files that can be moved to cheap storage and re-imported at any time.
            </span>
            <span>
              Archive your old indices automatically by setting your index retention strategy to archive
              on the{' '} <Link to={Routes.SYSTEM.INDICES.LIST}>indices</Link>{' '}
              page.
            </span>
            <span>
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES')}>
                <Button bsStyle="info">Overview</Button>
              </LinkContainer>
              &nbsp;
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_CONFIGURATION')}>
                  <Button bsStyle="info">Configuration</Button>
                </LinkContainer>
              &nbsp;
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS')}>
                  <Button bsStyle="info" className="active">Manage Backends</Button>
              </LinkContainer>
            </span>
          </PageHeader>
          <ArchiveLicenseStatusContainer />
          <Row className="content archive-backends">
            <Col lg={12}>
              <div className="pull-right">
                <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS_NEW')}>
                  <Button bsStyle="success">Create Backend</Button>
                </LinkContainer>
              </div>
              <ArchiveBackends backends={this.state.backends.backends}
                               pagination={this.state.backends.pagination}
                               onDelete={this._onDelete}
                               onPageChange={this._onPageChange} />
            </Col>
          </Row>
        </div>
      </DocumentTitle>
    );
  },
});

export default ArchiveBackendsPage;
