exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cNoe0zk-AU0ii-XObSliV{padding-left:10px;padding-right:10px;padding-bottom:5px;margin-bottom:5px;font-weight:600}._2_ZQqscxMCCBvSzbYLmm4m{opacity:.3}._2wCLcxo-rzlhoZOgK5-DQB{color:#16ace3}", ""]);

// exports
exports.locals = {
	"dropdownheader": "cNoe0zk-AU0ii-XObSliV",
	"disabled": "_2_ZQqscxMCCBvSzbYLmm4m",
	"active": "_2wCLcxo-rzlhoZOgK5-DQB"
};