import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router';
import Routes from 'routing/Routes';

import styles from './LicenseNotification.css';

class LicenseTrialNotification extends React.Component {
  static propTypes = {
    expiresIn: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className={`container-fluid ${styles.licenseNotification}`}>
        <Alert bsStyle="info">
          <h4 style={{ marginBottom: 5 }}><strong>Graylog Enterprise Trial License</strong></h4>
          <p>
            Your trial license will expire in <strong>{this.props.expiresIn}</strong>.
            Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>License page</Link> for
            more information or contact your Graylog account manager
          </p>
        </Alert>
      </div>
    );
  }
}

export default LicenseTrialNotification;
