import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Routes from 'routing/Routes';

class ReportContentsToolbar extends React.Component {
  static propTypes = {
    action: PropTypes.oneOf(['create', 'edit']).isRequired,
    formElementId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLoading: false,
  };

  render() {
    const { action, formElementId, isLoading, onCancel } = this.props;
    let submitText;
    if (action === 'create') {
      submitText = isLoading ? 'Creating report...' : 'Create report';
    } else {
      submitText = isLoading ? 'Updating report...' : 'Update report';
    }
    return (
      <ButtonToolbar>
        <Button type="submit" form={formElementId} bsStyle="success" disabled={isLoading}>
          {submitText}
        </Button>
        <LinkContainer to={Routes.pluginRoute('REPORTS')} onClick={onCancel}>
          <Button className="cancel">Cancel</Button>
        </LinkContainer>
      </ButtonToolbar>
    );
  }
}

export default ReportContentsToolbar;
