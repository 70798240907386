import Reflux from 'reflux';
import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import { get } from 'lodash';

const licenseStatusUrl = '/plugins/org.graylog.plugins.license/licenses/status/for-subject?subject=/license/enterprise/views';

const ViewsLicenseActions = Reflux.createActions({ status: { asyncResult: true } });
const ViewsLicenseStore = Reflux.createStore({
  listenables: [ViewsLicenseActions],

  getInitialState() {
    return {
      status: undefined,
      missing: true,
      loading: true,
    };
  },

  init() {
    this.status();
  },

  status() {
    const promise = fetch('GET', URLUtils.qualifyUrl(licenseStatusUrl))
      .then(
        response => ({ status: response.status, missing: false, loading: false }),
        (error) => {
          const status = get(error, 'additional.status');
          if (status === 404) {
            return { status: undefined, missing: true, loading: false };
          }

          const errorMessage = get(error, 'additional.body.message') || get(error, 'message');

          return { status: undefined, missing: true, loading: false, error: errorMessage };
        });

    promise.then(result => this.trigger(result));

    ViewsLicenseActions.status.promise(promise);

    return promise;
  },
});

export { ViewsLicenseStore, ViewsLicenseActions };
