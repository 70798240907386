exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1EoXQQGfxuxD0KK5ICKoUM{opacity:.3;background-color:#9e1f63;width:40px;height:40px;position:fixed;bottom:60px;right:20px;border-radius:5px;border:none}._1EoXQQGfxuxD0KK5ICKoUM.middle{right:35%}._1EoXQQGfxuxD0KK5ICKoUM :hover{opacity:1}._3Hjg3HFvWB-FYHcdZPpucB{color:#fff;position:absolute;top:50%;left:50%;margin-top:-9px;margin-left:-5px}", ""]);

// exports
exports.locals = {
	"scroll": "_1EoXQQGfxuxD0KK5ICKoUM",
	"arrowUp": "_3Hjg3HFvWB-FYHcdZPpucB"
};