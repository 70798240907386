import React from 'react';
import PropTypes from 'prop-types';
import { PluginStore } from 'graylog-web-plugin/plugin';

import { WidgetVisualizationNotFound } from 'components/widgets';
import StoreProvider from 'injection/StoreProvider';
import Caption from './Caption';
import Heading from './Heading';
import WidgetDescription from './WidgetDescription';

import style from './ReportingWidget.css';

const WidgetsStore = StoreProvider.getStore('Widgets');

class ReportingWidget extends React.Component {
  static propTypes = {
    dashboardId: PropTypes.string.isRequired,
    widget: PropTypes.object.isRequired,
    showHeading: PropTypes.bool,
    showCaption: PropTypes.bool,
    showHandle: PropTypes.bool,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    onRenderComplete: PropTypes.func,
    interactive: PropTypes.bool,
    limitHeight: PropTypes.bool,
  };

  static defaultProps = {
    showHeading: true,
    showCaption: true,
    showHandle: true,
    onRenderComplete: () => {},
    interactive: true,
    limitHeight: false,
  };

  constructor(props) {
    super(props);
    this.state = this._cleanState();
  }

  componentDidMount() {
    this._loadValue();
  }

  componentWillReceiveProps(nextProps) {
    if (this.getWidgetId(nextProps.widget) !== this.getWidgetId(this.props.widget)) {
      this.setState(this._cleanState());
    }
  }

  componentDidUpdate(prevProps) {
    if (this.getWidgetId(prevProps.widget) !== this.getWidgetId(this.props.widget)) {
      this._loadValue();
    }
  }

  /*
   * This allows us to handle reporting widgets (ID in `dashboard_widget_id`) and dashboard widgets (ID in `id`)
   * interchangeably.
   */
  getWidgetId = (widget) => {
    return widget.dashboard_widget_id || widget.id;
  };

  _cleanState = () => {
    return {
      result: undefined,
      calculatedAt: undefined,
      error: false,
      errorMessage: undefined,
    };
  };

  _loadValue = () => {
    const { dashboardId, widget, width } = this.props;

    WidgetsStore.loadValue(dashboardId, this.getWidgetId(widget), width)
      .then(
        (value) => {
          // Avoid updating state if the result didn't change
          if (value.calculated_at === this.state.calculatedAt) {
            return;
          }

          const newState = {
            result: value.result,
            calculatedAt: value.calculated_at,
            error: false,
            errorMessage: undefined,
          };

          if (value.computation_time_range) {
            newState.computationTimeRange = value.computation_time_range;
          }

          this.setState(newState);
        },
        (response) => {
          const error = response.message;
          const newResult = this.state.result === undefined ? 'N/A' : this.state.result;
          this.setState({
            result: newResult,
            error: true,
            errorMessage: `Error loading widget value: ${error}`,
          });
        });
  };

  _getVisualization = (widget) => {
    if (widget.type === '') {
      return null;
    }

    if (this.state.result === undefined) {
      return (
        <div className={style.loading}>
          <i className="fa fa-spin fa-refresh spinner" />
        </div>
      );
    }

    if (this.state.result === 'N/A') {
      return <div className={style.notAvailable}>{this.state.result}</div>;
    }

    const { height, width, onRenderComplete, interactive, limitHeight } = this.props;

    const widgetPlugin = PluginStore.exports('widgets').find(w => w.type.toUpperCase() === widget.type.toUpperCase());
    if (!widgetPlugin) {
      return <WidgetVisualizationNotFound widgetClassName={widget.type} onRenderComplete={onRenderComplete} />;
    }

    const { result, computationTimeRange } = this.state;
    const headingHeight = this._heading ? this._heading.scrollHeight : 0;
    const captionHeight = this._caption ? this._caption.scrollHeight : 0;
    const marginHeight = 10;
    return React.createElement(widgetPlugin.visualizationComponent, {
      id: this.getWidgetId(widget),
      config: widget.config,
      data: result,
      height: height - headingHeight - captionHeight - marginHeight,
      width: width,
      computationTimeRange: computationTimeRange,
      onRenderComplete: onRenderComplete,
      interactive: interactive,
      limitHeight: limitHeight,
      locked: true,
      field: widget.config.field,
    });
  };

  render() {
    const { widget, showCaption, showHeading, showHandle } = this.props;

    return (
      <div className={style.reportVisualization}>
        {showHandle && <div className={style.draggableHandle}><i className="fa fa-sort" /></div>}
        {showHeading && (
          <div className={style.heading} ref={(c) => { this._heading = c; }}>
            <Heading title={widget.description} />
          </div>
        )}
        <div className={style.visualizationContainer}>
          {this._getVisualization(widget)}
        </div>
        {showCaption && (
          <div className={style.caption} ref={(c) => { this._caption = c; }}>
            <Caption text={<WidgetDescription widget={widget} calculatedAt={this.state.cal} />} />
          </div>
        )}
      </div>
    );
  }
}

export default ReportingWidget;
