import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ReportLayout from './ReportLayout';
import ReportScheduling from './ReportScheduling';

class ReportConfiguration extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    reportLogo: PropTypes.string,
  };

  static defaultProps = {
    reportLogo: undefined,
  };

  render() {
    const { report, reportLogo } = this.props;

    return (
      <Row>
        <Col lg={5}>
          <ReportScheduling report={report} />
        </Col>
        <Col lg={6} lgOffset={1}>
          <ReportLayout report={report} reportLogo={reportLogo} />
        </Col>
      </Row>
    );
  }
}

export default ReportConfiguration;
