exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".kXqc9mA05EjaS_96_mebS{padding-left:10px;padding-right:10px;padding-bottom:5px;margin-bottom:5px;font-weight:600}._1zRDoCqWsVLiJ5iOhX3XH-{margin-bottom:10px}._33TMa7HS8oy10jHUo7KYNl{margin-top:10px}", ""]);

// exports
exports.locals = {
	"dropdownheader": "kXqc9mA05EjaS_96_mebS",
	"bottomSpacer": "_1zRDoCqWsVLiJ5iOhX3XH-",
	"topSpacer": "_33TMa7HS8oy10jHUo7KYNl"
};