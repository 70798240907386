exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-jhz-Lds8IbOsbS4L2glh{font-size:14px;margin-top:15px;width:95%}._19tXaQf1HfznO9jETH-5AZ{white-space:pre-wrap}._1xpu8P7I9inGAJVKc88gg9 img{border:1px solid #e3e3e3}", ""]);

// exports
exports.locals = {
	"reportPreview": "-jhz-Lds8IbOsbS4L2glh",
	"descriptionPreview": "_19tXaQf1HfznO9jETH-5AZ",
	"logo": "_1xpu8P7I9inGAJVKc88gg9"
};