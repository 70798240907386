import React from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router';
import Routes from 'routing/Routes';

import styles from './LicenseNotification.css';

class LicenseViolationNotification extends React.Component {
  render() {
    return (
      <div className={`container-fluid ${styles.licenseNotification}`}>
        <Alert bsStyle="danger">
          <h4 style={{ marginBottom: 5 }}><strong>Graylog Enterprise License Violation</strong></h4>
          <p>
              At least one term of your Graylog Enterprise license has been violated.
              Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>License page</Link> for
              more information or contact your Graylog account manager.
          </p>
        </Alert>
      </div>
    );
  }
}

export default LicenseViolationNotification;
