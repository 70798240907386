import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import DocsHelper from 'util/DocsHelper';
import Routes from 'routing/Routes';
import { DocumentTitle, PageHeader } from 'components/common';

import AuditLogLicenseStatusContainer from 'auditlog/AuditLogLicenseStatusContainer';
import AuditLogStatus from 'auditlog/AuditLogStatus';

class AuditLogConfigurationPage extends React.Component {
  render() {
    return (
      <DocumentTitle title="Audit log configuration">
        <div>
          <PageHeader title="Audit Log Configuration">
            <span>
              Graylog Audit Log lets you create a chronological record of activities performed in Graylog, that you
              can later use to reconstruct and examine the sequence of events that occurred when investigating an event.
            </span>
            <span>
              You can modify the audit log configuration in the Graylog configuration file. Please remember to restart
              the server afterwards.

              &nbsp;
              <a href={DocsHelper.toString('auditlog.html')} target="_blank">Read more in the documentation</a>.
            </span>
            <span>
              <LinkContainer to={Routes.pluginRoute('SYSTEM_AUDITLOG')}>
                <Button bsStyle="info">Overview</Button>
              </LinkContainer>
              &nbsp;
              <LinkContainer to={Routes.pluginRoute('SYSTEM_AUDITLOG_CONFIGURATION')}>
                <Button bsStyle="info" className="active">Configuration</Button>
              </LinkContainer>
            </span>
          </PageHeader>

          <AuditLogLicenseStatusContainer />

          <Row className="content">
            <Col md={12}>
              <AuditLogStatus />
            </Col>
          </Row>
        </div>
      </DocumentTitle>
    );
  }
}

export default AuditLogConfigurationPage;
