import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Reflux from 'reflux';
import ReportLicenseActions from '../../ReportLicenseActions';
import ReportLicenseStore from '../../ReportLicenseStore';
import ReportLicenseStatus from './ReportLicenseStatus';
import { licenseIsLoading } from '../../logic/license';

const ReportLicenseStatusContainer = createReactClass({
  displayName: 'ReportLicenseStatusContainer',
  propTypes: {
    message: PropTypes.string,
    summary: PropTypes.bool,
  },
  mixins: [Reflux.connect(ReportLicenseStore, 'licenseStatus')],

  getDefaultProps() {
    return {
      message: undefined,
      summary: false,
    };
  },

  componentDidMount() {
    ReportLicenseActions.getLicenseStatus();
  },

  render() {
    if (licenseIsLoading(this.state.licenseStatus)) {
      return null;
    }

    return <ReportLicenseStatus licenseStatus={this.state.licenseStatus} summary={this.props.summary} message={this.props.message} />;
  },
});

export default ReportLicenseStatusContainer;
