import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';
import { Col, Row } from 'react-bootstrap';

import { DocumentTitle, PageHeader, Spinner } from 'components/common';

import ReportHeaderToolbar from '../common/ReportHeaderToolbar';
import ReportValidations from '../common/ReportValidations';
import ReportConfiguration from './ReportConfiguration';
import ReportsStore from '../ReportsStore';
import ReportsActions from '../ReportsActions';

const ReportConfigurationPage = createReactClass({
  displayName: 'ReportConfigurationPage',

  propTypes: {
    params: PropTypes.object.isRequired,
  },

  mixins: [Reflux.connect(ReportsStore)],

  componentDidMount() {
    this._loadReport(this.props.params.reportId);
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.params.reportId !== nextProps.params.reportId) {
      this._loadReport(nextProps.params.reportId);
    }
  },

  _loadReport(id) {
    ReportsActions.get(id);
    ReportsActions.getReportLogo(id);
  },

  render() {
    const { report, reportLogo } = this.state;
    const isLoading = !report;
    if (isLoading) {
      return <Spinner text="Loading report data, please wait..." />;
    }

    return (
      <DocumentTitle title={`Report ${report.title} configuration`}>
        <div>
          <PageHeader title={<span>Report <em>{report.title}</em> configuration</span>}>
            <span>
              Configure the report layout and schedule, adapting it to your needs.
            </span>
            {null}
            <ReportHeaderToolbar report={report} active="REPORTS_REPORTID_CONFIGURATION" />
          </PageHeader>

          <ReportValidations />

          <Row className="content">
            <Col md={12}>
              <ReportConfiguration report={report} reportLogo={reportLogo} />
            </Col>
          </Row>
        </div>
      </DocumentTitle>
    );
  },
});

export default ReportConfigurationPage;
