exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".IAz1th4kc_KW_9uENwY7p{margin-bottom:15px;line-height:1.3em}@media print{.IAz1th4kc_KW_9uENwY7p{margin-top:15px;page-break-after:avoid}}", ""]);

// exports
exports.locals = {
	"heading": "IAz1th4kc_KW_9uENwY7p"
};