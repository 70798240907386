exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._192-klK2rISBVEFROCxJ73{border-right:1px solid #d1d1d1}._2xx-sXeZURIX8_d2WP9wIC{margin-bottom:15px}._1oVYtnZQmLsnL-7zIpD6hh{padding:7px}", ""]);

// exports
exports.locals = {
	"subnavigation": "_192-klK2rISBVEFROCxJ73",
	"horizontal": "_2xx-sXeZURIX8_d2WP9wIC",
	"horizontalPreviousNextButtons": "_1oVYtnZQmLsnL-7zIpD6hh"
};