import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router';
import Routes from 'routing/Routes';

const NoValidViewsLicense = ({ license }) => {
  const errorMessage = license && license.error ? (
    <p>The following error has occurred while retrieving the license: {license.error}</p>
  ) : null;
  return (
    <Row className="content">
      <Col md={12}>
        <Alert bsStyle="danger">
          <h4 style={{ fontWeight: 'bold', marginBottom: 5 }}>Views are disabled</h4>
          <p>
            Views are disabled because there is no valid license.
          </p>
          {errorMessage}
          <p>
            See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
          </p>
        </Alert>
      </Col>
    </Row>
  );
};

NoValidViewsLicense.propTypes = {
  license: PropTypes.shape({
    error: PropTypes.any,
  }),
};

NoValidViewsLicense.defaultProps = {
  license: {
    error: 'No valid license exists.',
  },
};

export default NoValidViewsLicense;
