import React from 'react';
import createReactClass from 'create-react-class';

import { ViewsLicenseActions } from 'enterprise/stores/ViewsLicenseStore';
import LicenseCheck from './LicenseCheck';
import NoValidViewsLicense from '../NoValidViewsLicense';

export default Component => createReactClass({
  componentDidMount() {
    ViewsLicenseActions.status();
  },
  render() {
    return (
      <LicenseCheck errorComponent={NoValidViewsLicense}>
        <Component {...this.props} />
      </LicenseCheck>
    );
  },
});
