import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { DocumentTitle, PageHeader } from 'components/common';
import connect from 'stores/connect';

import ReportList from './ReportList';
import ReportValidations from '../common/ReportValidations';
import HeaderToolbar from '../common/HeaderToolbar';

import ReportLicenseActions from '../ReportLicenseActions';
import ReportLicenseStore from '../ReportLicenseStore';

class ReportsPage extends React.Component {
  static propTypes = {
    licenseStatus: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    ReportLicenseActions.getLicenseStatus();
  }

  render() {
    const { licenseStatus } = this.props;
    return (
      <DocumentTitle title="Reports">
        <div>
          <PageHeader title="Reports">
            <span>
              Reports let you relay information from Graylog in a presentable form. They are based on dashboard
              widgets, highly customizable and can be scheduled for automatic delivery.
            </span>
            {null}
            <HeaderToolbar active="REPORTS" licenseStatus={licenseStatus} />
          </PageHeader>

          <ReportValidations />

          <Row className="content">
            <Col md={12}>
              <ReportList licenseStatus={licenseStatus} />
            </Col>
          </Row>
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(ReportsPage, { licenseStatus: ReportLicenseStore });
