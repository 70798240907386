/* global REPLACE_MODULES */
// eslint-disable-next-line no-unused-vars, import/default
import webpackEntry from './webpack-entry';

import Immutable from 'immutable';

import archiveBindings from 'archive/bindings';
import auditlogBindings from 'auditlog/bindings';
import enterpriseBindings from 'enterprise/bindings';
import licenseBindings from 'license/bindings';
import reportBindings from 'report/bindings';
import EnterpriseMenuBindings from './EnterpriseMenuBindings';

import { PluginManifest, PluginStore } from 'graylog-web-plugin/plugin';

import packageJson from '../../package.json';

const merger = (oldVal, newVal) => oldVal.concat(newVal);
const bindings = Immutable.Map().mergeWith(merger, archiveBindings, auditlogBindings, enterpriseBindings, licenseBindings, reportBindings, EnterpriseMenuBindings);
const manifest = new PluginManifest(packageJson, bindings.toJS());

PluginStore.register(manifest);
