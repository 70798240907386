import * as Permissions from './enterprise/Permissions';

const extendedSearchPath = '/extendedsearch';
const viewsPath = '/views';

export default {
  navigation: [
    {
      description: 'Enterprise',
      children: [
        { path: extendedSearchPath, description: 'Extended Search', permissions: Permissions.ExtendedSearch.Use },
        { path: viewsPath, description: 'Views', permissions: Permissions.View.Use },
        { path: '/system/archives', description: 'Archives', permissions: 'archive:read' },
        { path: '/system/auditlog', description: 'Audit Log', permissions: 'auditlog_status:read' },
        { path: '/system/licenses', description: 'Licenses', permissions: 'inputs:create' },
        { path: '/reports', description: 'Reports', permissions: 'report:update' },
      ],
    },
  ],
};
