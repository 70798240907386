exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Mzm86UX3sM7ClgjZWIcfi{text-align:center;width:25px}.Mzm86UX3sM7ClgjZWIcfi>i{font-size:1.333em;line-height:.75em}._2raCUetExLpQP8l5iVyxL5{width:300px}", ""]);

// exports
exports.locals = {
	"status": "Mzm86UX3sM7ClgjZWIcfi",
	"date": "_2raCUetExLpQP8l5iVyxL5"
};