import PropTypes from 'prop-types';
import React from 'react';

import createReactClass from 'create-react-class';

import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';

const ArchiveRetentionStrategySummary = createReactClass({
  displayName: 'ArchiveRetentionStrategySummary',

  propTypes: {
    config: PropTypes.object.isRequired,
  },

  componentDidMount() {
    this.style.use();
  },

  componentWillUnmount() {
    this.style.unuse();
  },

  style: require('!style/useable!css!archive/style.css'),

  render() {
    return (
      <div>
        <dl>
          <dt>Index retention strategy:</dt>
          <dd>Archive</dd>
          <dt>Max number of indices:</dt>
          <dd>{this.props.config.max_number_of_indices}</dd>
          <dt>Index action:</dt>
          <dd>{this.props.config.index_action}</dd>
        </dl>
        <ArchiveLicenseStatusContainer summary />
      </div>
    );
  },
});

export default ArchiveRetentionStrategySummary;
