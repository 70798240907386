exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._13o5wb3OcMwtl03Y-dQ24{height:100%;text-align:center;padding-bottom:10px}._31UpMnmKwGQCPKm4dDFECT{height:100%;width:100%}._1sHCwUVuoPljBkHvSzj8Xd{dominant-baseline:central;text-anchor:middle}", ""]);

// exports
exports.locals = {
	"container": "_13o5wb3OcMwtl03Y-dQ24",
	"number": "_31UpMnmKwGQCPKm4dDFECT",
	"value": "_1sHCwUVuoPljBkHvSzj8Xd"
};