import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';

const WIDGET_ASPECT_RATIO = 10 / 16;

class ReportingWidgetContainer extends React.Component {
  static propTypes = {
    onDimensionsChange: PropTypes.func,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    onDimensionsChange: () => {},
  };

  state = {
    height: undefined,
    width: undefined,
  };

  componentDidMount() {
    this._propagateDimensions();
    window.addEventListener('resize', this._onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize);
  }

  _areDimensionsSet = () => {
    return this.state.height !== undefined && this.state.width !== undefined;
  };

  _onResize = lodash.throttle(() => {
    if (this._areDimensionsSet()) {
      this._propagateDimensions(true);
    }
  }, 100);

  _propagateDimensions = (isUpdate) => {
    const dimensions = this._calculateContainerDimensions(isUpdate);
    if (this.state.height !== dimensions.height || this.state.width !== dimensions.width) {
      this.setState(dimensions);
      this.props.onDimensionsChange(dimensions);
    }
  };

  _calculateContainerDimensions = (isUpdate) => {
    if (!this._container) {
      return {};
    }

    // On first render we need to include the whole available space in consideration
    const widthKey = isUpdate ? 'clientWidth' : 'scrollWidth';

    const width = this._container[widthKey];
    return {
      height: width * WIDGET_ASPECT_RATIO,
      width: width,
    };
  };

  render() {
    return (
      <div ref={(c) => { this._container = c; }} style={{ height: '100%', width: '100%' }}>
        {this._areDimensionsSet() && (
          React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, { height: this.state.height, width: this.state.width });
          })
        )}
      </div>
    );
  }
}

export default ReportingWidgetContainer;
