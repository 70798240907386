exports = module.exports = require("../../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2msQDmbZ3qhTCYSMlPtx_i{background-color:#eee;padding:10px;margin:5px;border-radius:6px}._3zU50Cdnom4i-JoUM-amGM{padding-bottom:5px;text-transform:uppercase}", ""]);

// exports
exports.locals = {
	"descriptionBox": "_2msQDmbZ3qhTCYSMlPtx_i",
	"description": "_3zU50Cdnom4i-JoUM-amGM"
};