export default class ViewSharing {
  constructor(viewId) {
    this._value = { viewId };
  }

  static __registrations: { [string]: typeof ViewSharing } = {};

  get viewId() {
    return this._value.viewId;
  }

  static create(viewId) {
    return new ViewSharing(viewId);
  }

  toJSON() {
    const { type, viewId } = this;

    return {
      type,
      view_id: viewId,
    };
  }

  static fromJSON(value) {
    // eslint-disable-next-line camelcase
    const { type, view_id } = value;

    const implementingClass = ViewSharing.__registrations[type.toLocaleLowerCase()];

    if (implementingClass) {
      return implementingClass.fromJSON(value);
    }

    return ViewSharing.create(view_id);
  }

  static registerSubtype(type: string, implementingClass: typeof ViewSharing) {
    this.__registrations[type.toLocaleLowerCase()] = implementingClass;
  }
}
