exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ADsMw9AupxauCnuuMn7AE{padding-left:0;margin-bottom:20px}.ADsMw9AupxauCnuuMn7AE .ADsMw9AupxauCnuuMn7AE{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"list": "ADsMw9AupxauCnuuMn7AE"
};