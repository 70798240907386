import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

const LicenseTerm = createReactClass({
  propTypes: {
    violated: PropTypes.bool.isRequired,
    warning: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  },

  getDefaultProps() {
    return {
      children: null,
    };
  },

  render() {
    if (!this.props.violated && !this.props.children) {
      return null;
    }
    let warning = null;
    if (this.props.violated) {
      warning = (<span>
        <i className="fa fa-warning text-danger" />&nbsp;{this.props.warning}{this.props.warning ? <br /> : null}
      </span>);
    }

    return (
      <div>
        {warning}
        {this.props.children}
        {this.props.children ? <br /> : null}
      </div>
    );
  },
});

export default LicenseTerm;
