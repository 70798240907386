// @flow strict
import React from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';

// $FlowFixMe: imports from core need to be fixed in flow
import Routes from 'routing/Routes';
import SelectViewWithParameter from './SelectViewWithParameter';

type Props = {
  onClose: () => void,
  value: any,
};

class ExecuteViewWithValue extends React.Component<Props> {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
  };

  _onSubmit = (selectedView: ?string, selectedParameter: ?string) => {
    this.props.onClose();

    if (selectedView && selectedParameter) {
      const uri = new URI(Routes.pluginRoute('VIEWS_VIEWID')(selectedView)).search({ [selectedParameter]: this.props.value });
      const newWindow = window.open(uri.toString(), '_blank');
      newWindow.focus();
    }
  };

  render() {
    return <SelectViewWithParameter onSubmit={this._onSubmit} />;
  }
}

export default ExecuteViewWithValue;
