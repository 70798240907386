exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2fSrPBVOSbcU-y37JMfYpz{padding-left:0;padding-right:0}._2fSrPBVOSbcU-y37JMfYpz .popover-content{min-width:200px;padding:0}._2fSrPBVOSbcU-y37JMfYpz .list-group{margin-bottom:0}._2fSrPBVOSbcU-y37JMfYpz .list-group-item{border-right:0;border-left:0;padding:6px 15px}._2fSrPBVOSbcU-y37JMfYpz .list-group-item:first-child{border-top-right-radius:0;border-top-left-radius:0}._2fSrPBVOSbcU-y37JMfYpz .list-group-item:last-child{border-bottom:0}._32nZd_0WpdzWIDi9xmpS6u{max-height:340px;overflow:auto}._1QfDaivZuv9xU2lH0SLycM{margin-bottom:0!important;padding:5px}", ""]);

// exports
exports.locals = {
	"customPopover": "_2fSrPBVOSbcU-y37JMfYpz",
	"scrollableList": "_32nZd_0WpdzWIDi9xmpS6u",
	"dataFilterInput": "_1QfDaivZuv9xU2lH0SLycM"
};