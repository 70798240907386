exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3t4gaHCWJSXYTQSGIS197M{background-color:#dff0d8;color:#3c763d;display:table}._3vB7sxibhaB18pTo-gEdZE{background-color:#f2dede;color:#a94442;display:table;text-decoration:line-through}._9G4lPcfMEdPWBcKAR_5xG{background-color:#d9edf7;color:#31708f;display:table;text-decoration:line-through}._29Jy5rI9MlHWaAv7leZSmd{font-family:monospace}", ""]);

// exports
exports.locals = {
	"added-fields": "_3t4gaHCWJSXYTQSGIS197M",
	"removed-fields": "_3vB7sxibhaB18pTo-gEdZE",
	"mutated-fields": "_9G4lPcfMEdPWBcKAR_5xG",
	"field-value": "_29Jy5rI9MlHWaAv7leZSmd"
};