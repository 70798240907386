exports = module.exports = require("../../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2LPMQDyg65hPCK0bv5w49p{min-width:fit-content}", ""]);

// exports
exports.locals = {
	"draggedElement": "_2LPMQDyg65hPCK0bv5w49p"
};