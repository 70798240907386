exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".usNBDFuSFAWYkt7XfG1X_{font-size:85%}.usNBDFuSFAWYkt7XfG1X_ strong{font-weight:600}.HuOz8D5lMrLC1HbfCKKeI{padding:20px 0 40px}.DwYQs7fEArlolo58z8_34,.HuOz8D5lMrLC1HbfCKKeI{white-space:pre-wrap}.HuOz8D5lMrLC1HbfCKKeI h2{margin-bottom:10px}.HuOz8D5lMrLC1HbfCKKeI+hr{border:none;border-top:2px solid #aaa;color:#aaa;height:5px;overflow:visible;text-align:center}.HuOz8D5lMrLC1HbfCKKeI+hr:after{background:#fff;content:\"end of cover page\";font-family:monospace;padding:0 4px;position:relative;top:-.9em}._2vJVxNkuMW9IsitXszC1Hg{padding:10px;margin-top:20px;text-align:center}", ""]);

// exports
exports.locals = {
	"alert": "usNBDFuSFAWYkt7XfG1X_",
	"coverPage": "HuOz8D5lMrLC1HbfCKKeI",
	"description": "DwYQs7fEArlolo58z8_34",
	"logo": "_2vJVxNkuMW9IsitXszC1Hg"
};