import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { Button } from 'react-bootstrap';
import { Select } from 'components/common';
import naturalSort from 'javascript-natural-sort';

import NumberUtils from 'util/NumberUtils';

const ArchiveCreationForm = createReactClass({
  displayName: 'ArchiveCreationForm',

  propTypes: {
    indices: PropTypes.object.isRequired,
    createArchive: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      selectedIndex: this._selectedIndex(),
    };
  },

  componentDidMount() {
    this.style.use();
  },

  componentWillUnmount() {
    this.style.unuse();
  },

  style: require('!style/useable!css!archive/style.css'),

  _formatSelectOptions() {
    const indices = this.props.indices;
    const indexNames = Object.keys(indices).filter((indexName) => !indexName.endsWith('restored_archive'));
    let options = [];

    if (indexNames.length > 0) {
      options = indexNames.map((indexName) => {
        const docs = indices[indexName].documentCount;
        const size = NumberUtils.formatBytes(indices[indexName].storeSize);
        return {
          value: indexName,
          label: `${indexName} (${NumberUtils.formatNumber(docs)} documents / ${size})`,
          disabled: docs < 1,
        };
      });
      options.sort((a, b) => naturalSort(b.value.toLowerCase(), a.value.toLowerCase()));
    } else {
      options.push({ value: 'none', label: 'No indices available', disabled: true });
    }

    return options;
  },

  _onIndexSelect(selection) {
    if (selection === '') {
      this.setState(this.getInitialState());
    }

    this.setState({ selectedIndex: selection });
  },

  _selectedIndex() {
    return Object.keys(this.props.indices)
      .filter((indexName) => !indexName.endsWith('restored_archive'))
      .sort((a, b) => naturalSort(b.toLowerCase(), a.toLowerCase()))[0];
  },

  _onSubmit(e) {
    e.preventDefault();
    this.props.createArchive(this.state.selectedIndex);
  },

  _isButtonDisabled() {
    return !this.state.selectedIndex || this.props.indices[this.state.selectedIndex].documentCount < 1;
  },

  render() {
    return (
      <span>
        <h2>Create Archive for Index</h2>
        <p style={{ marginTop: 5 }}>Select an index to be archived to disk. This action will not close or delete the original index.</p>
        <form className="form-inline indexSelection" onSubmit={this._onSubmit}>
          <div className="form-group">
            <Select placeholder="Select index" options={this._formatSelectOptions()} matchProp="value" onChange={this._onIndexSelect} value={this._selectedIndex()} />
          </div>
          &nbsp;
          <Button bsStyle="success" type="submit" disabled={this._isButtonDisabled()}>Archive index</Button>
        </form>
      </span>
    );
  },
});

export default ArchiveCreationForm;
