exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tc2ZwgiKl2vrLBvU_PLdF{color:#a6a6a6;font-size:.8em;margin:10px auto 0;text-align:center;width:75%}@media print{.tc2ZwgiKl2vrLBvU_PLdF{page-break-before:avoid;page-break-inside:avoid}}", ""]);

// exports
exports.locals = {
	"caption": "tc2ZwgiKl2vrLBvU_PLdF"
};