import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Reflux from 'reflux';
import AuditLogLicenseActions from 'auditlog/AuditLogLicenseActions';
import AuditLogLicenseStore from 'auditlog/AuditLogLicenseStore';
import AuditLogLicenseStatus from 'auditlog/AuditLogLicenseStatus';

const ArchiveLicenseStatusContainer = createReactClass({
  displayName: 'ArchiveLicenseStatusContainer',

  propTypes: {
    summary: PropTypes.bool,
  },

  mixins: [Reflux.connect(AuditLogLicenseStore, 'licenseStatus')],

  componentDidMount() {
    AuditLogLicenseActions.getLicenseStatus();
  },

  render() {
    if (this.state.licenseStatus.loading) {
      return null;
    }

    return <AuditLogLicenseStatus licenseStatus={this.state.licenseStatus} summary={this.props.summary} />;
  },
});

export default ArchiveLicenseStatusContainer;
