import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Reflux from 'reflux';
import ArchiveLicenseActions from 'archive/ArchiveLicenseActions';
import ArchiveLicenseStore from 'archive/ArchiveLicenseStore';
import ArchiveLicenseStatus from 'archive/components/ArchiveLicenseStatus';

const ArchiveLicenseStatusContainer = createReactClass({
  displayName: 'ArchiveLicenseStatusContainer',

  propTypes: {
    summary: PropTypes.bool,
  },

  mixins: [Reflux.connect(ArchiveLicenseStore, 'licenseStatus')],

  componentDidMount() {
    ArchiveLicenseActions.getLicenseStatus();
  },

  render() {
    if (this.state.licenseStatus.loading) {
      return null;
    }

    return <ArchiveLicenseStatus licenseStatus={this.state.licenseStatus} summary={this.props.summary} />;
  },
});

export default ArchiveLicenseStatusContainer;
