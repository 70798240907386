import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { Alert, Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import BootstrapModalForm from 'components/bootstrap/BootstrapModalForm';
import { Timestamp } from 'components/common';

const ImportLicenseModal = createReactClass({
  displayName: 'ImportLicenseModal',

  propTypes: {
    verifiedLicense: PropTypes.object,
    errorMessage: PropTypes.string,
    verifyLicense: PropTypes.func.isRequired,
    saveLicense: PropTypes.func.isRequired,
  },

  getDefaultProps() {
    return {
      errorMessage: undefined,
      verifiedLicense: undefined,
    };
  },

  getInitialState() {
    return {
      token: undefined,
    };
  },

  openModal() {
    this.refs.modal.open();
  },

  _closeModal() {
    this.refs.modal.close();
  },

  _saved() {
    this._closeModal();
    this.setState(this.getInitialState());
  },

  _save() {
    if (! this.props.errorMessage) {
      this.props.saveLicense(this.state.token, this._saved);
    }
  },

  _updateLicenseInformation(ev) {
    const token = ev.target.value.trim();
    this.setState({ token: token });

    if (token) {
      this.props.verifyLicense(token);
    }
  },

  render() {
    let licensePreview = null;
    let errorMessage = null;
    if (this.props.errorMessage) {
      errorMessage = <p><b>Reason</b>: {this.props.errorMessage}</p>;
    }
    if (this.props.verifiedLicense) {
      const l = this.props.verifiedLicense;
      licensePreview = (
        <dl style={{ marginBottom: 0 }}>
          <dt>Licensed to</dt>
          <dd>{`${l.audience.company}: ${l.audience.name} <${l.audience.email}>`}</dd>
          <dt>Valid until</dt>
          <dd><Timestamp dateTime={l.expiration_date} relative/></dd>
        </dl>
      );
    }

    let licenseStatus;
    if (this.state.token) {
      licenseStatus = (
        <Alert bsStyle={this.props.errorMessage ? 'danger' : 'success'}>
          <h3 style={{ marginBottom: 5 }}>{this.props.errorMessage ? 'Invalid' : 'Valid'} license</h3>
          {errorMessage}
          {licensePreview}
        </Alert>
      );
    } else {
      licenseStatus = (
        <Alert bsStyle="info">
          Please add the license key for your cluster in the text field above.
        </Alert>
      );
    }

    return (
      <span>
        <Button onClick={this.openModal}
                bsStyle="success">
          {'Import new license'}
        </Button>
        <BootstrapModalForm ref="modal"
                            title={'Import new Graylog license'}
                            onSubmitForm={this._save}
                            submitButtonText="Import">
          <fieldset>
            <FormGroup id="licenseTextarea">
              <ControlLabel>License</ControlLabel>
              <FormControl componentClass="textarea" defaultValue={this.state.token}
                           onChange={this._updateLicenseInformation} rows={5} autoFocus required />
            </FormGroup>
          </fieldset>
          {licenseStatus}
        </BootstrapModalForm>
      </span>
    );
  },
});

export default ImportLicenseModal;
