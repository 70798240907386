exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._30YPWPuSHp-p8DJx2otSDA:first-of-type{padding-top:15px;padding-left:10px;padding-right:10px}._30YPWPuSHp-p8DJx2otSDA:not(:first-of-type){padding-top:5px;padding-left:10px;padding-right:10px}", ""]);

// exports
exports.locals = {
	"licenseNotification": "_30YPWPuSHp-p8DJx2otSDA"
};