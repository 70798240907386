import Reflux from 'reflux';

import UserNotification from 'util/UserNotification';
import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import LicensesActions from 'license/LicensesActions';

const urlPrefix = '/plugins/org.graylog.plugins.license';

const LicensesStore = Reflux.createStore({
  listenables: [LicensesActions],
  licenses: undefined,
  licensesStatus: undefined,
  verifiedLicense: undefined,
  errorMessage: undefined,

  getInitialState() {
    return {
      licenses: this.licenses,
      licensesStatus: this.licensesStatus,
      verifiedLicense: this.verifiedLicense,
      errorMessage: this.errorMessage,
    };
  },

  list() {
    const failCallback = (error) => {
      UserNotification.error(`Fetching licenses failed with status: ${error.message}`,
        'Could not retrieve license information');
    };

    const url = URLUtils.qualifyUrl(`${urlPrefix}/licenses`);
    const promise = fetch('GET', url);
    promise.then((response) => {
      this.licenses = response;
      this.trigger({ licenses: response });
    }, failCallback);

    LicensesActions.list.promise(promise);
  },

  listStatus() {
    const failCallback = (error) => {
      UserNotification.error(`Fetching licenses status failed with status: ${error.message}`,
        'Could not retrieve license status information');
    };

    const url = URLUtils.qualifyUrl(`${urlPrefix}/licenses/status`);
    const promise = fetch('GET', url);
    promise.then((response) => {
      this.licensesStatus = response.status;
      this.trigger({ licensesStatus: response.status });
    }, failCallback);

    LicensesActions.listStatus.promise(promise);
  },

  verify(token) {
    const failCallback = (error) => {
      UserNotification.error(`Verifying license failed with status: ${error.message}`,
        'Could not retrieve license information');
    };

    const url = URLUtils.qualifyUrl(`${urlPrefix}/licenses/verify`);
    const promise = fetch('POST', url, token);
    promise.then((response) => {
      this.verifiedLicense = response.license;
      this.errorMessage = response.error_message;
      this.trigger({ verifiedLicense: this.verifiedLicense, errorMessage: this.errorMessage });
    }, failCallback);

    LicensesActions.verify.promise(promise);
  },

  save(token) {
    const failCallback = (error) => {
      UserNotification.error(`Uploading license failed with status: ${error.message}`,
        'Could not upload new license');
    };

    const url = URLUtils.qualifyUrl(`${urlPrefix}/licenses`);
    const promise = fetch('POST', url, token);
    promise.then((response) => {
      this.licenses = ([response].concat(this.licenses ? this.licenses : []));
      this.verifiedLicense = undefined;
      this.errorMessage = undefined;
      this.trigger({ licenses: this.licenses, verifiedLicense: this.verifiedLicense, errorMessage: this.errorMessage });
    }, failCallback);

    LicensesActions.save.promise(promise);
  },

  remove(id) {
    const failCallback = (error) => {
      UserNotification.error(`Removing license failed with status: ${error.message}`,
        'Could not remove license');
    };

    const url = URLUtils.qualifyUrl(`${urlPrefix}/licenses/${id}`);
    const promise = fetch('DELETE', url);
    promise.then(() => {
      this.licenses = (this.licenses ? this.licenses : []).filter(license => license.id !== id);
      this.trigger({ licenses: this.licenses });
    }, failCallback);

    LicensesActions.remove.promise(promise);
  },
});

export default LicensesStore;
