import PropTypes from 'prop-types';
import React from 'react';
import lodash from 'lodash';

import { Select } from 'components/common';
import StringUtils from 'util/StringUtils';

class DayOfMonthSelect extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: undefined,
    disabled: false,
    required: false,
  };

  render() {
    const { id, value, disabled, required, onChange } = this.props;

    const dom = lodash.range(1, 31 + 1).map((day) => {
      const stringDay = String(day);
      return { value: stringDay, label: stringDay };
    });

    const stringValue = value ? StringUtils.stringify(value) : undefined;

    return (
      <Select id={id}
              options={dom}
              value={stringValue}
              placeholder="Select day of the month"
              disabled={disabled}
              valueRenderer={option => <span>Day {option.label}</span>}
              onChange={onChange}
              required={required} />
    );
  }
}

export default DayOfMonthSelect;
