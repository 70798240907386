exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3pfAYAucGNKt9ELUADogwy{display:flex;flex-direction:column;align-content:flex-start}.DaD1YRppUf37sZ9nAgiMS{overflow:hidden;padding:20px 40px}.DaD1YRppUf37sZ9nAgiMS:after{content:\"\";position:absolute;bottom:0;left:0;right:0;height:20px;background:linear-gradient(hsla(0,0%,100%,.3),#fff 80%)}@media screen{.DaD1YRppUf37sZ9nAgiMS{border:1px solid #d1d1d1;background-color:#fff;cursor:move}}", ""]);

// exports
exports.locals = {
	"widgetList": "_3pfAYAucGNKt9ELUADogwy",
	"widgetContainer": "DaD1YRppUf37sZ9nAgiMS"
};